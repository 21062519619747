import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class FooterWithoutMenuLightSocialOnly extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <React.Fragment>
                <footer className="footer footer-bar bg-light">
                    <div className="container text-center">
                        <div className="row align-items-center">
                            <div className="col-sm-6">
                                <div className="text-sm-left">
                                    <p className="mb-0 text-dark">type1who.com © {new Date().getFullYear()} Powered by <a href="https://klementtan.com">klementtan.com</a></p>
                                </div>
                            </div>

                            <div className="col-sm-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <ul className="list-unstyled social-icon social text-sm-right mb-0">
                                    <li className="list-inline-item mb-0"><a href="https://www.instagram.com/type1who/" rel="noopener noreferrer" target="_blank" className="rounded mr-1 text-dark"><i className="mdi mdi-instagram" title="Instagram"></i></a></li>
                                    <li className="list-inline-item mb-0"><a href="mailto:hello@type1who.com" rel="noopener noreferrer" target="_blank" className="rounded  mr-1  text-dark"><i className="mdi mdi-email-outline" title="email"></i></a></li>
                                    <li className="list-inline-item mb-0"><a href="https://www.pinterest.com/type1who/" rel="noopener noreferrer" target="_blank" className="rounded mr-1 text-dark"><i className="mdi mdi-pinterest" title="Pintrest"></i></a></li>
                                    <li className="list-inline-item mb-0"><a href="https://www.facebook.com/pg/type1who/" rel="noopener noreferrer" target="_blank" className="rounded  mr-1  text-dark"><i className="mdi mdi-facebook" title="Facebook"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        );
    }
}

export default FooterWithoutMenuLightSocialOnly;
