import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import {Row, Col} from "reactstrap"

// Import Images

import type1whoSVG from "../../images/icon/type1who-logo.svg";
import sunsetSVG from "../../images/icon/sunset.svg";

class Topbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      page: false,
      landing: false,
      docs: false,
      new: false,
      utility: false,
      user: false,
      work: false,
      blog: false,
      carr: false
    };
    this.toggleLine = this.toggleLine.bind(this);
  }

  toggleLine() {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }));
  }

  componentDidMount() {
    var matchingMenuItem = null;
    var ul = document.getElementById("top-menu");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  activateParentDropdown = item => {
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add("active"); // li
      const parent1 = parent.parentElement;
      parent1.classList.add("active"); // li
      if (parent1) {
        const parent2 = parent1.parentElement;
        parent2.classList.add("active"); // li
        if (parent2) {
          const parent3 = parent2.parentElement;
          parent3.classList.add("active"); // li
          if (parent3) {
            const parent4 = parent3.parentElement;
            parent4.classList.add("active"); // li
          }
        }
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <header id="topnav" className="defaultscroll sticky nav-sticky">
          <div className="container">
            <div>
              <Link id="navlogo" className="logo" to="/">
                <div className="row">
                  <span className="logo-text">Type 1 Wh </span>

                  <img 
                className="logo-image"
                src={sunsetSVG} alt="" />
                </div>
              </Link>
            </div>
            <div className="menu-extras">
              <div className="menu-item">
                <Link
                  to="#"
                  onClick={this.toggleLine}
                  className={
                    this.state.isOpen ? "navbar-toggle open" : "navbar-toggle"
                  }
                >
                  <div className="lines">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </Link>
              </div>
            </div>

            <div
              id="navigation"
              style={{ display: this.state.isOpen ? "block" : "none" }}
            >
              <ul className="navigation-menu" id="top-menu">
                <li>
                  <Link to="/blog">Blog</Link>
                </li>
                <li className="has-submenu">
                  <Link
                    to="/miaomiao"
                    onClick={event => {
                      event.preventDefault();
                      this.setState({ landing: !this.state.landing });
                    }}
                  >
                    MiaoMiao
                  </Link>
                  <span className="menu-arrow"></span>
                  <ul
                    className={
                      this.state.landing
                        ? "submenu megamenu open"
                        : "submenu megamenu"
                    }
                  >
                    <li>
                      <ul>
                        {/* TODO add links */}
                        <li>
                          <Link to="/miaomiao/free-trial">MiaoMiao Free Trial(SG ONLY)</Link>
                        </li>
                        <li>
                          <Link to="/miaomiao">What is Miao Miao</Link>
                        </li>
                        <li>
                          <Link to="/miaomiao/set-up">MiaoMiao Set-up</Link>
                        </li>
                        <li>
                          <Link to="/miaomiao/how-to-use">How to Use MiaoMiao</Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </header>
      </React.Fragment>
    );
  }
}

export default withRouter(Topbar);
